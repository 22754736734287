import React from 'react';
import HeaderImage from '../assets/SHE 2.jpg';
import Environment from '../assets/The Environment.png';
import HR from '../assets/Work at Ghana Gas.png';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';
import Document from '../documents/Env Policy.pdf';

function Profile() {
  let navigate = useNavigate();

  const Env = () => {
    navigate("/theenvironment")
  }

  const HRP = () => {
    navigate("/hrpolicy")
  }
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>
      <div>
        <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Health Safety and Environment Policy Statement  </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          GHANA NATIONAL GAS LIMITED COMPANY (GHANA GAS) is committed to protecting the environment, health and safety of its workforce and others affected by the Company’s business activities in gathering, processing, transporting and marketing of natural gas resources in Ghana. </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          In line with this commitment, we shall: <br /><br />
          Protect the environment through the prevention of pollution, promotion of sustainable resource use, mitigation of climate change, protection of ecosystems, and promotion of sustainable practices in all areas of operations. <br /><br />
          Provide safe and healthy working conditions for the prevention of work-related injury and/or ill-health, eliminate hazards, and reduce Occupational Health and Safety (OH&S) risks during operations. <br /><br />
          Promote effective waste management strategies by reducing, re-using, recycling and ensuring safe disposal of waste. <br /><br />
          Involve relevant interested parties in the fulfilment of compliance obligations. <br /><br />
          Develop and maintain a highly motivated competent workforce, and strive for continual improvement of the Health, Safety and Environment (HSE) Integrated Management System performance. <br /><br />
          Ensure the consultation and participation of workers, and where they exist, worker representatives in decision making processes. <br /><br />
          Ensure that this HSE Policy provides the basis for setting progressive goals, objectives and targets aimed at minimizing environmental impacts and preventing pollution and work-related injuries and/or ill-health. <br /><br />
          Communicate this HSE Policy to the workforce and all other interested parties.   <br /><br />
          Ensure that responsibilities and authorities for relevant roles within the HSE Integrated Management System are assigned and communicated at all levels within the organization. <br /><br />
          Ensure that in all work activities we are guided by the following key principles on HSE, which Managers and Supervisors shall reinforce:<br />
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•Do it safely or not at all.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•Doing it right the first time.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•When in doubt find out.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          Periodically evaluate the effectiveness and suitability of the HSE Integrated Management System to ensure continual improvement.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          The Chief Executive Officer (CEO) has the overall authority and responsibility of ensuring that adequate resources are provided to enhance the smooth implementation of this Health, Safety and Environment (HSE) Policy. </h1>
                  

        <div className="relative hover:cursor-pointer" onClick={Env}>
          <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              The Environment
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
            src={Environment}
            alt="Ghana Gas GPP"
          />
        </div>

        <div className="relative hover:cursor-pointer" onClick={HRP}>
          <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              HR Philosophy
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
            src={HR}
            alt="Ghana Gas GPP"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
